import { useMarketsBookStore } from 'markets-store/marketsbook/store/marketsBookStore'
import type {
  FilterConfig,
  MarketsInFilters,
} from 'markets-store/marketsbook/types'
import type { TSportEvent } from 'markets-store/types'
import {
  MARKET_ALLOWED_STATES,
  SPORT_EVENT_STATUSES_ENUM,
} from 'markets-store/constants'
import { groupBy } from '@st/utils'
import { useLocalizeEventFilters } from '../useLocalizeEventFilters'

export function useMainMarket(event: Ref<TSportEvent>) {
  const marketsBookStore = useMarketsBookStore()
  const { marketsFiltersConfig } = storeToRefs(marketsBookStore)

  const eventMarkets = useMarketsByEventId(() => event.value.id)
  const activeMarkets = computed(() =>
    eventMarkets.value.filter(
      (market) =>
        MARKET_ALLOWED_STATES.includes(market.status) &&
        market.outcomes?.length,
    ),
  )
  const marketsGroupedById = computed(() =>
    groupBy(activeMarkets.value, (item) => item.id),
  )
  const eventState = computed<'live' | 'prematch'>(() =>
    event.value.status === SPORT_EVENT_STATUSES_ENUM.live ? 'live' : 'prematch',
  )

  function checkMarketFromConfigAvailable(
    config: FilterConfig,
    line: keyof MarketsInFilters = 'main',
  ) {
    const marketsIds = config.markets[line] ?? []
    const isSomeMarketAvailable = marketsIds.some(
      (id) => marketsGroupedById.value.get(id).length,
    )
    return isSomeMarketAvailable ? config : null
  }

  const mainMarketConfig = computed(() => {
    if (!marketsFiltersConfig.value.default) return null

    const marketsFiltersByState =
      (marketsFiltersConfig.value[event.value.sportId] ||
        marketsFiltersConfig.value.default)[eventState.value] ?? []

    const [firstMarket, secondMarket, thirdMarket] = marketsFiltersByState

    return (
      checkMarketFromConfigAvailable(firstMarket) ??
      checkMarketFromConfigAvailable(secondMarket) ??
      checkMarketFromConfigAvailable(thirdMarket)
    )
  })

  const localizeEventFilters = useLocalizeEventFilters()

  const marketName = computed(() => {
    if (!mainMarketConfig.value) return ''

    const { titlei18n, type } = mainMarketConfig.value ?? {}
    if (type) {
      return localizeEventFilters(titlei18n)
    }
    return ''
  })

  return {
    activeMarkets,
    marketsGroupedById,
    mainMarketConfig,
    marketName,
    checkMarketFromConfigAvailable,
  }
}
