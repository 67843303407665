<template>
  <div class="score-board-mini" data-t="score-board-mini">
    <Scores
      :period-scores="periodScores"
      :stats="event.match?.stats"
      :server="server"
      :is-default="isDefault"
      :is-compact="props.compact"
      :only-scores="props.onlyScores"
      :size="props.size"
    />
  </div>
</template>

<script setup lang="ts">
import type { TSportEvent } from 'markets-store'
import { SPORT_IDS, CYBER_SPORT_ID } from 'markets-store/constants'
import { useEventServer, usePeriodScores } from '../../composables'

import Scores from './Scores.vue'

const props = withDefaults(
  defineProps<{
    event: TSportEvent
    compact?: boolean
    size?: 's' | 'm'
    onlyScores?: boolean
  }>(),
  {
    size: 's',
  },
)

const { event } = toRefs(props)
const { server } = useEventServer(event)
const periodScores = usePeriodScores(event)

const isDefault = ![
  SPORT_IDS.AUSTRALIAN_FOOTBALL,
  SPORT_IDS.BADMINTON,
  SPORT_IDS.BALL_HOCKEY,
  SPORT_IDS.BASEBALL,
  SPORT_IDS.BASKETBALL,
  SPORT_IDS.BASKETBALL_STREET,
  SPORT_IDS.SOCCER_BEACH,
  SPORT_IDS.VOLLEYBALL_BEACH,
  SPORT_IDS.BOWLES,
  SPORT_IDS.CRICKET,
  SPORT_IDS.DARTS,
  SPORT_IDS.FIELD_HOCKEY,
  SPORT_IDS.AMERICAN_FOOTBALL,
  SPORT_IDS.FOOTSAL,
  SPORT_IDS.HANDBALL,
  SPORT_IDS.ICE_HOCKEY,
  SPORT_IDS.KABADDI,
  SPORT_IDS.RUGBY,
  SPORT_IDS.SNOOKER,
  SPORT_IDS.SOCCER,
  SPORT_IDS.SQUASH,
  SPORT_IDS.TABLE_TENNIS,
  SPORT_IDS.TENNIS,
  SPORT_IDS.VOLLEYBALL,
  SPORT_IDS.WATERPOLO,
  CYBER_SPORT_ID,
].includes(event.value.sportId)
</script>
