export type EventFilters =
  | 'bothToScore'
  | 'doubleChance'
  | 'exodus'
  | 'gameWinner'
  | 'handicap'
  | 'it1st'
  | 'it2st'
  | 'neither'
  | 'nextGoal'
  | 'nextPoint'
  | 'nextRun'
  | 'no'
  | 'noGoal'
  | 'over'
  | 'raceUp'
  | 'total'
  | 'under'
  | 'winner'
  | 'x'
  | 'yes'

export function useLocalizeEventFilters(
  i18n = useI18n(),
): (eventFilters: EventFilters) => string {
  const locales: Record<EventFilters, string> = {
    bothToScore: i18n.t('marketsFilters.bothToScore'),
    doubleChance: i18n.t('marketsFilters.doubleChance'),
    exodus: i18n.t('marketsFilters.exodus'),
    gameWinner: i18n.t('marketsFilters.gameWinner'),
    handicap: i18n.t('marketsFilters.handicap'),
    it1st: i18n.t('marketsFilters.it1st'),
    it2st: i18n.t('marketsFilters.it2st'),
    neither: i18n.t('marketsFilters.neither'),
    nextGoal: i18n.t('marketsFilters.nextGoal'),
    nextPoint: i18n.t('marketsFilters.nextPoint'),
    nextRun: i18n.t('marketsFilters.nextRun'),
    no: i18n.t('marketsFilters.no'),
    noGoal: i18n.t('marketsFilters.noGoal'),
    over: i18n.t('marketsFilters.over'),
    raceUp: i18n.t('marketsFilters.raceUp'),
    total: i18n.t('marketsFilters.total'),
    under: i18n.t('marketsFilters.under'),
    winner: i18n.t('marketsFilters.winner'),
    x: i18n.t('marketsFilters.x'),
    yes: i18n.t('marketsFilters.yes'),
  }

  return (eventFilters) => locales[eventFilters]
}
